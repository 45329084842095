.App {
  font-family: sans-serif;
  text-align: center;
}

/* starting ENTER animation */
.zoomoutin-enter {
  opacity:0;
  transform: translateY(0%) scale(0.9);
}

/* ending ENTER animation */
.zoomoutin-enter-active {
  opacity:1;
  transform: translateY(0%) scale(1);
  transition: all 100ms ease-in-out;
}

/* starting EXIT animation */
.zoomoutin-exit {
  opacity:1;
  transform: translateY(0%) scale(1);
}

/* ending EXIT animation */
.zoomoutin-exit-active {
  opacity:0;
  transform: translateY(0%) scale(0.9);
  transition: all 100ms ease-in-out;
}

